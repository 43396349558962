/* CSS for finse */
@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}
:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.not-for-ansatte {
  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not(.red):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red)
    input[type="button"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  .header-search-expand,
  button:not(.red):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: @color-primary !important;
    .transition(all, 0.5s, ease-in);
    &:focus,
    &:hover {
      background-color: @color-primary--dark !important;
    }
  }
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
  }
  #head-wrapper {
    background: @color-secondary url("../images/banner.jpg") no-repeat scroll
      top center;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio1/images/head-dropdown-toggle-black.png")
        no-repeat scroll 100% 53%;
    }
    #head {
      .uio-app-name {
        display: none;
      }
      height: 233px;
      .head-menu {
        right: 124px;
        .language {
          display: none;
        }
        a {
          color: @color-neutral--dark;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        .logo {
          position: absolute;
          top: 194px;
          left: 14px;
          color: @color-light;
          font-family: verdana, sans-serif;
          font-size: 26px;
          font-weight: bold;
        }
        .uio-logo-link,
        .uib-logo-link {
          display: block;
          height: 70px;
          position: absolute;
          width: 70px;
          span {
            display: none;
          }
        }
        .uio-logo-link {
          left: 770px;
          top: 133px;
        }
        .uib-logo-link {
          left: 870px;
          top: 133px;
        }
      }
    }
  }
}
#globalnav {
  background: @color-primary none repeat scroll 0 0;
  .gradient(top, @color-primary, @color-primary--dark);
  ul li {
    a,
    &.vrtx-active-item a {
      color: @color-light;
      &:hover,
      &:focus {
        background: @color-neutral--light none repeat scroll 0 0;
        color: @color-dark;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-dark;
    }
  }
}

#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-child a {
    color: @color-dark;
    background-color: @color-neutral--light;
  }
  a {
    &.vrtx-marked {
      background-color: @color-secondary !important;
      color: @color-dark;
      border: none;
    }
  }
}

table th {
  font-weight: bold;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    background-color: @color-secondary;
    *,
    a {
      color: @color-neutral--dark;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0)
        url("../uio1/images/dropdown-black.png")
        no-repeat scroll right center / 22px auto;
      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper {
      background: transparent url("../images/banner.jpg") no-repeat scroll
        bottom left;
      height: 178px;
      #head {
        background-size: auto;
        height: 130px;
        #header .logo {
          padding-left: 10px;
          left: 16px;
          top: 90px;
          font-size: 17px;
        }
        .uio-logo-link,
        .uib-logo-link {
          display: block;
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-primary none repeat scroll 0 0;
    }
  }
  .menu-search {
    input[type="text"] {
      border-right-width: 7em !important;
      padding-right: 0px;
    }
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
}

/* Mixin */

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

.gradient (@origin: left, @start: #ffffff, @stop: #000000) {
  background-color: @start;
  background-image: -webkit-linear-gradient(@origin, @start, @stop);
  background-image: -moz-linear-gradient(@origin, @start, @stop);
  background-image: -o-linear-gradient(@origin, @start, @stop);
  background-image: -ms-linear-gradient(@origin, @start, @stop);
  background-image: linear-gradient(@origin, @start, @stop);
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...

/*@color-primary          : #618BEF;*/
@color-primary : #3a5c81;
@color-primary--dark : darken(spin(@color-primary, -5), 25%);
/*@color-secondary        : #4269C4;*/
@color-secondary : #DBE8F9;
@color-secondary--dark : darken(spin(@color-secondary, -5), 25%);
@color-link : #2771bb;

// Neutral colors
@color-neutral--dark : #2b2b2b;
@color-neutral : #949494;
@color-neutral--light : #EAEDEE;
@color-lines : #F0F0F0;

// Suplementary colors
@color-light : #ffffff;
@color-dark : #000000;
@footer-color : @color-neutral--light;

// Spacing
@unit : 5px;
@spacing--xlarge : 100px;
@spacing--large : 40px;
@spacing : 20px;
@spacing--small : 10px;
